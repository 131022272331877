body {
  margin: 0;
  padding: 0;
  font-family: "Adelle Sans Light";
}

#root {
  margin: 0;
}

@font-face {
  font-family: "Adelle Sans Bold";
  src: url("../public/fonts/AdelleSansBold.otf");
}

@font-face {
  font-family: "Adelle Sans Light";
  src: url("../public/fonts/AdelleSansLight.otf");
}
